/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirm } from '../../../../store/UserSlice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const validationSchema = yup.object({
  code: yup
    .string()
    .trim()
    .required('El código de verificación es requerida.'),
});

const Form = (): JSX.Element => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const email = location.state?.email;

  const formik = useFormik({
    initialValues: {
      code: '',
      email: email,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        await dispatch(confirm({
          user_name: values.email,
          confirm_code: values.code,
        }) as any);
        setStatus({ success: true });
        setSubmitError(null);
        setSubmitSuccess('Cuenta confirmada exitosamente');
        navigate('/signin');
      } catch (error) {
        console.error('Error confirmando cuenta:', error);
        setStatus({ success: false });
        setSubmitError('Error confirmando cuenta');
        setSubmitSuccess(null);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Confirmar cuenta
        </Typography>
        {/* <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          ¿Olvidaste tu contraseña?
        </Typography> */}
        <Typography color="text.secondary">
          Ingresa el código de verificación que fue enviado a tu correo electrónico.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa el código de recuperación
            </Typography>
            <TextField
              required
              fullWidth
              id="code"
              label="Código de Recuperación"
              name="code"
              autoComplete="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Confirmar cuenta
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
