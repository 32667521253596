import React from 'react';

import {
  Expo as ExpoView,
  Dashboard as DashboardView,
  ChatBot as ChatBotView,
  Rewards as RewardsView,
  SigninCover as SigninCoverView,
  SignupCover as SignupCoverView,
  SignoutCover as SignoutCoverView,
  AccountBilling as AccountBillingView,
  AccountGeneral as AccountGeneralView,
  AccountNotifications as AccountNotificationsView,
  AccountSecurity as AccountSecurityView,
  AccountConfirm as AccountConfirmView,
  PasswordResetCover as PasswordResetCoverView,
  PasswordChangeCover as PasswordChangeCoverView,
  CompanyTerms as CompanyTermsView,
  NotFoundCover as NotFoundCoverView,
} from './index';

const routes = [
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <ExpoView {...params} />,
  },
  {
    path: '/dashboard',
    renderer: (params = {}): JSX.Element => <DashboardView {...params} />,
  },
  {
    path: '/chatbot',
    renderer: (params = {}): JSX.Element => <ChatBotView {...params} />,
  },
  {
    path: '/rewards',
    renderer: (params = {}): JSX.Element => <RewardsView {...params} />,
  },
  {
    path: '/signup',
    renderer: (params = {}): JSX.Element => <SignupCoverView {...params} />,
  },
  {
    path: '/signin',
    renderer: (params = {}): JSX.Element => <SigninCoverView {...params} />,
  },
  {
    path: '/signout',
    renderer: (params = {}): JSX.Element => <SignoutCoverView {...params} />,
  },
  {
    path: '/account',
    renderer: (params = {}): JSX.Element => <AccountGeneralView {...params} />,
  },
  {
    path: '/account-billing',
    renderer: (params = {}): JSX.Element => <AccountBillingView {...params} />,
  },
  {
    path: '/account-notifications',
    renderer: (params = {}): JSX.Element => <AccountNotificationsView {...params} />,
  },
  {
    path: '/account-security',
    renderer: (params = {}): JSX.Element => <AccountSecurityView {...params} />,
  },
  {
    path: '/account-confirm',
    renderer: (params = {}): JSX.Element => <AccountConfirmView {...params} />,
  },
  {
    path: '/password-reset',
    renderer: (params = {}): JSX.Element => <PasswordResetCoverView {...params} />,
  },
  {
    path: '/password-change',
    renderer: (params = {}): JSX.Element => <PasswordChangeCoverView {...params} />,
  },
  {
    path: '/company-terms',
    renderer: (params = {}): JSX.Element => <CompanyTermsView {...params} />,
  },
  {
    path: '/not-found-cover',
    renderer: (params = {}): JSX.Element => <NotFoundCoverView {...params} />,
  }
];

export default routes;
