import { configureStore } from '@reduxjs/toolkit';
import authReducer from './AuthSlice';
import passwordReducer from './PasswordSlice';
import chatReducer from './ChatSlice';
import rewardReducer from './RewardSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    password: passwordReducer,
    chat: chatReducer,
    reward: rewardReducer,
  },
});

export default store;