import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../../../store/AuthSlice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Porfavor ingresa un nombre válido')
    .max(50, 'Porfavor ingresa un nombre válido')
    .required('Porfavor especifica tu nombre'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Porfavor ingresa un apellido válido')
    .max(50, 'Porfavor ingresa un apellido válido')
    .required('Porfavor especifica tu apellido'),
  email: yup
    .string()
    .trim()
    .email('Porfavor ingresa un correo válido')
    .required('El correo es requerido.'),
  password: yup
    .string()
    .required('Porfavor ingresa tu contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
  birthday: yup
    .date()
    .required('Por favor ingresa tu fecha de nacimiento'),
});

const Form = (): JSX.Element => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    birthday: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        await dispatch(signUp({
          user_name: values.firstName,
          user_family_name: values.lastName,
          password: values.password,
          email: values.email,
          birthday: values.birthday,
        }) as any);
        setStatus({ success: true });
        setSubmitError(null);
        setSubmitSuccess('Usuario registrado correctamente');
        navigate('/account-confirm', { state: { email: values.email } });
      } catch (error) {
        console.error('Error registrando usuario:', error);
        setStatus({ success: false });
        setSubmitError('Error registrando usuario');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Registrarse
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Crear una cuenta
        </Typography>
        <Typography color="text.secondary">
          Completa el formulario para comenzar.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu nombre
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={'firstName'}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              // @ts-ignore
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu apellido
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              // @ts-ignore
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu correo
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              // @ts-ignore
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="birthday"
              label="Fecha de Nacimiento"
              type="date"
              id="birthday"
              InputLabelProps={{ shrink: true }}
              value={formik.values.birthday}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.birthday && Boolean(formik.errors.birthday)}
              helperText={formik.touched.birthday && formik.errors.birthday}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu contraseña
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              // @ts-ignore
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  ¿Ya tienes una cuenta?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/signin'}
                    underline={'none'}
                  >
                    Inicia sesión.
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Registrarse
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'text.secondary'}
              align={'center'}
            >
              Al darle click al botón "Registrarse" estás de acuerdo con nuestros{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/company-terms'}
                underline={'none'}
              >
                términos y condiciones.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
