import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const forgotPassword = createAsyncThunk<any, any>(
  'password/forgotPassword',
  async (email) => {
    const request = await axios.post(`${baseUrl}/auth/forgot-password`, { email });
    return request.data;
  }
);

export const recoveryPassword = createAsyncThunk<any, any>(
  'password/recoveryPassword',
  async (data) => {
    const request = await axios.post(`${baseUrl}/auth/recovery-password`, data);
    return request.data;
  }
);

const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(recoveryPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(recoveryPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(recoveryPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default passwordSlice.reducer;