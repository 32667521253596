import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/AuthSlice';
  
const SignoutCover = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = false;
  useEffect(() => {
    const signout = async () => {
      const token  = localStorage.getItem('token');
      await dispatch(signOut({ access_token: token}) as any);
      navigate('/signin');
    };
    signout();
  }, [navigate, token]);

  return (
    <div>
      Cerrando sesión...
    </div>
  );
};

export default SignoutCover;