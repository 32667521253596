/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recoveryPassword } from '../../../../store/PasswordSlice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Porfavor ingresa tu contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
  confirm: yup
    .string()
    .required('Porfavor ingresa tu contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
  email: yup
    .string()
    .trim()
    .email('Por favor, ingresa un correo válido')
    .required('Email es requerido.'),
});

const Form = (): JSX.Element => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        await dispatch(recoveryPassword({
          user_name: values.email,
          new_password: values.password,
          confirm_code: values.code,
        }) as any);
        setStatus({ success: true });
        setSubmitError(null);
        setSubmitSuccess('Contraseña restablecida exitosamente');
        navigate('/signin');
      } catch (error) {
        console.error('Error restableciendo la contraseña:', error);
        setStatus({ success: false });
        setSubmitError('Error restableciendo la contraseña');
        setSubmitSuccess(null);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Cambia tu contraseña
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          ¿Olvidaste tu contraseña?
        </Typography>
        <Typography color="text.secondary">
          Ingresa el código enviado a tu correo para restablecer tu contraseña.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu correo
            </Typography>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa la nueva contraseña
            </Typography>
            <TextField
              required
              fullWidth
              name="password"
              label="Nueva Contraseña"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa el código de recuperación
            </Typography>
            <TextField
              required
              fullWidth
              id="code"
              label="Código de Recuperación"
              name="code"
              autoComplete="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  component={Link}
                  href={'/signin'}
                  fullWidth
                >
                  Regresa a iniciar sesión
                </Button>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Cambiar contraseña
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
