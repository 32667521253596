import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setThread, sendMessage } from '../../store/ChatSlice';
import Main from '../../layouts/Main';
import Box from '@mui/material/Box';
import Container from '../../components/Container';
import {
  MessageInput,
  MessageList,
  Sidebar
} from './components';

const ChatBot = (): JSX.Element => {
  const [email, setEmail] = useState(null);
  const [newThread, setNewThread] = useState(null);
  const [messages, setMessages] = useState<{ role: 'user' | 'bot'; content: string }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [instruction, setInstruction] = useState<string>('Eres LearnIso, un asistente que ayuda a los estudiantes de las normas ISO 27001 e ISO 27032.' );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setEmail(localStorage.getItem('email'));
    const fetchThread = async () => {
      const response = await dispatch(setThread({}) as any);
      setNewThread(response.payload.thread_id);
    };
    fetchThread();
  }, []);

  const handleSendMessage = async (newMessage: string) => {
    setLoading(true);
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: newMessage }]);
    try {
      const response = await dispatch(sendMessage({ thread_id: newThread, message: newMessage, email }) as any);
      const botMessage = response.payload.trim();
      setMessages((prevMessages) => [...prevMessages, { role: 'bot', content: botMessage }]);
    } catch (error) {
      console.error('Error fetching response from OpenAI', error);
      if (error.response?.data?.error) {
        setError(error.response.data.error.message);
      } else {
        setError('An error occurred while fetching the response from OpenAI');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectInstruction = (systemMessage: string, userMessage: string) => {
    setInstruction(systemMessage);
    handleSendMessage(userMessage);
  };

  return(
    <Main colorInvert={true}>
      <Box bgcolor={'alternate.main'} height="100vh" display="flex" flexDirection="column">
        <Sidebar onSelectInstruction={handleSelectInstruction} />
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box flex="1" overflow="auto">
            <h3>¡Hola Adrián, pregúntame lo que necesites saber!</h3>
            <MessageList messages={messages} loading={loading} />
          </Box>
          <Box>
            <MessageInput onSendMessage={handleSendMessage}/>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default ChatBot;