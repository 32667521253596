import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getReward = createAsyncThunk<any, any>(
  'reward/get',
  async (email) => {
    const request = await axios.get(`${baseUrl}/rewards/email/${email}`);
    const response = await request.data.data;
    return response;
  }
);

export const claimReward = createAsyncThunk<any, any>(
  'reward/claim',
  async (params) => {
    const request = await axios.post(`${baseUrl}/users/rewards/claim/${params.id}`, {'email': params.email});
    const response = await request.data.data;
    return response;
  }
);

const rewardSlice = createSlice({
  name: 'reward',
  initialState: {
    loading: false,
    user: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReward.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(getReward.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(getReward.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(claimReward.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(claimReward.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(claimReward.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    });
  }
});

export default rewardSlice.reducer;