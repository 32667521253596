/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Description = (): JSX.Element => {
  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 900,
          color: 'common.white',
        }}
      >
        Descubre Learn-ISO: la plataforma emergente en ciberseguridad
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.primary"
        sx={{
          fontWeight: 500,
          color: 'common.white',
        }}
      >
        Learn-ISO se está convirtiendo en el referente para estudiantes que 
        desean dominar las normas ISO en ciberseguridad. Con una plataforma 
        innovadora y un enfoque práctico, Learn-ISO es la herramienta perfecta 
        para quienes buscan destacarse en el mundo de la tecnología y la seguridad 
        de la información. Aprovecha esta oportunidad única para impulsar tu carrera 
        y obtener las certificaciones que te abrirán las puertas en el competitivo 
        mercado tecnológico.
      </Typography>
    </Box>
  );
};

export default Description;
