/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../../../store/AuthSlice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Porfavor ingresa un correo válido')
    .required('El correo es requerido.'),
  password: yup
    .string()
    .required('Porfavor ingresa tu contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
});
const Form = (): JSX.Element => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        const resultAction = await dispatch(signIn({ user_name: values.email, password: values.password }) as any);
        if (signIn.fulfilled.match(resultAction)) {
          const { payload: { AuthenticationResult } } = resultAction;
          localStorage.setItem('token', AuthenticationResult.AccessToken);
          navigate('/dashboard');
        } else if (signIn.rejected.match(resultAction)) {
          setSubmitError(resultAction.error.message);
        }
      } catch (error) {
        console.error('Error iniciando sesión:', error);
        setStatus({ success: false });
        setSubmitError('Error iniciando sesión');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Iniciar sesión
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Bienvenido de vuelta
        </Typography>
        <Typography color="text.secondary">
          Iniciar sesión para administrar tu cuenta.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Ingresa tu correo
            </Typography>
            <TextField
              required
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Ingresa tu contraseña
                </Typography>
              </Box>
              <Typography variant={'subtitle2'}>
                <Link
                  component={'a'}
                  color={'primary'}
                  href={'/password-reset'}
                  underline={'none'}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </Typography>
            </Box>
            <TextField
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  ¿No tienes una cuenta todavía?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/signup'}
                    underline={'none'}
                  >
                    Regístrate aquí.
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Inicia sesión
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
