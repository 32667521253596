import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { NavItem } from './components';
interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  pages: {
    account: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const Topbar = ({
  pages,
  colorInvert = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    account: accountPages
  } = pages;
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="LearnISO"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://assets.learn-iso.com/learniso/logos/isotipo-black-150.svg'
              : 'https://assets.learn-iso.com/learniso/logos/isotipo-green-150.svg'
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={4}>
          <NavItem
            title={'Cuenta'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
