
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Por favor, ingrese un nombre válido')
    .max(50, 'El nombre no debe exceder los 50 caracteres')
    .required('El nombre es requerido.'),
  email: yup
    .string()
    .trim()
    .email('Por favor, ingrese un correo electrónico válido')
    .required('El Email es requerido.'),
  password: yup
    .string()
    .required('Por favor, ingrese una contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
});

const Hero = (): JSX.Element => {
  const theme = useTheme();

  const GridItemHeadlineBlock = () => (
    <Box marginBottom={4} data-aos="fade-up">
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: 900,
          color: 'common.white',
        }}
      >
        Únete a los estudiantes líderes en LearnISO 2024
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.primary"
        sx={{
          fontWeight: 500,
          color: 'common.white',
        }}
      >
        Ya sea que estés en universidades como Stanford, MIT o la UNAM, en LearnISO conecta con los futuros profesionales.
        <br />
        ¿Quieres más información? Registrate y empieza a alcanzar tus metas en LearnISO 2024.
      </Typography>
    </Box>
  );

  const GridItemFormBlock = (): JSX.Element => {
    const initialValues = {
      fullName: '',
      email: '',
      password: '',
    };

    const onSubmit = (values) => {
      return values;
    };

    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit,
    });

    return (
      <Box padding={{ xs: 3, sm: 6 }} width={1} component={Card} boxShadow={1}>
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection={'column'}>
            <Box marginBottom={4}>
              <TextField
                sx={{ height: 54 }}
                label="Nombre Completo"
                variant="outlined"
                color="primary"
                size="medium"
                name="fullName"
                fullWidth
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                // @ts-ignore
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
            </Box>
            <Box marginBottom={4}>
              <TextField
                sx={{ height: 54 }}
                label="Correo Electrónico"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                // @ts-ignore
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box marginBottom={4}>
              <TextField
                sx={{ height: 54 }}
                label="Contraseña"
                type="password"
                variant="outlined"
                color="primary"
                size="medium"
                name="password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                // @ts-ignore
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
            <Box>
              <Button
                sx={{ height: 54 }}
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                type="submit"
              >
                Crear una cuenta
              </Button>
            </Box>
            <Box marginY={4} marginX={{ xs: -3, sm: -6 }}>
              <Divider />
            </Box>
            <Box>
              <Typography component="p" variant="body2" align="left">
              Al crear su cuenta, acepta nuestra {' '}
                <Box
                  component="a"
                  href=""
                  color={theme.palette.text.primary}
                  fontWeight={'700'}
                >
                  Política de privacidad
                </Box>
                ,{' '}
                <Box
                  component="a"
                  href=""
                  color={theme.palette.text.primary}
                  fontWeight={'700'}
                >
                  Política de datos
                </Box>{' '}
                y{' '}
                <Box
                  component="a"
                  href=""
                  color={theme.palette.text.primary}
                  fontWeight={'700'}
                >
                  Política de cookies
                </Box>
                .
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
    );
  };

  return (
    <Box
      minHeight={300}
      height={'auto'}
      position={'relative'}
      sx={{
        marginTop: -13,
        paddingTop: 13,
        backgroundColor: theme.palette.alternate.main,
        background:
          'url(https://assets.maccarianagency.com/backgrounds/img19.jpg) no-repeat center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `linear-gradient(315deg, ${theme.palette.primary.main} 0%, #000000 74%)`,
          opacity: '0.8',
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <GridItemHeadlineBlock />
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <GridItemFormBlock />
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
