import React, { useState } from 'react';
import CountUp from 'react-countup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VisibilitySensor from 'react-visibility-sensor';

export const dataset = [
  {
    london: 5,
    month: 'semana 1',
  },
  {
    london: 8,
    month: 'semana 2',
  },
  {
    london: 15,
    month: 'semana3',
  },
  {
    london: 3,
    month: 'semana 4',
  },
];

const mock = [
  {
    title: 300,
    subtitle:
      'Has respondido correctamente 300 + de las preguntas en tus evaluaciones y simulaciones de entrevistas.',
    suffix: '+',
  },
  {
    title: 45,
    subtitle:
      'Has interactuado con LearnIso por más de 45 horas, aprovechando nuestras herramientas y recursos para tu aprendizaje.',
    suffix: '+',
  },
  {
    title: 70,
    subtitle: 'Ya has completado el 70% del contenido necesario para obtener tu certificación ISO 27001. ¡Sigue así, estás muy cerca de la meta!',
    suffix: '%',
  },
];

const DataSection = (): JSX.Element => {

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return(
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <Box marginY={6}>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid key={i} item xs={12} md={4}>
                <Typography variant="h4" color={'primary'} gutterBottom>
                  <Box fontWeight={600}>
                    <VisibilitySensor
                      onChange={(isVisible) =>
                        setViewPortVisibility(isVisible)
                      }
                      delayedCall
                    >
                      <CountUp
                        duration={2}
                        end={viewPortEntered ? item.title : 0}
                        start={0}
                        suffix={item.suffix}
                      />
                    </VisibilitySensor>
                  </Box>
                </Typography>
                <Typography component="p">{item.subtitle}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DataSection;