import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

interface MessageInputProps {
    onSendMessage: (newMessage: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState<string>('');
  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };
  return (
    <Box display="flex" flexDirection="row" alignItems="center" padding={2} bgcolor="background.paper">
      <TextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        variant="outlined"
        fullWidth
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSendMessage();
          }
        }}
      />
      <Button onClick={handleSendMessage} variant="contained" color="primary" style={{ marginLeft: '8px' }}>
        Enviar
      </Button>
    </Box>
  );
};

export default MessageInput;