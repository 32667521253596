import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface Message {
  role: 'user' | 'bot';
  content: string;
}
interface MessageListProps {
  messages: Message[];
  loading: boolean;
}

const MessageList: React.FC<MessageListProps> = ({ messages, loading }) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  return (
    <Box sx={{ p:2, overflowY: 'auto', height: '100%' }}>
      {messages.map((message, index) => (
        <Box key={index}
          sx= {{
            display: 'flex',
            justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
            mb: 2
          }}
        >
          <Box
            sx={{
              maxWidth: '70%',
              p: 2,
              bgcolor: message.role === 'user' ? 'primary.main' : 'grey.300',
              color: message.role === 'user' ? 'white' : 'black',
              borderRadius: 2,
            }}
          >
            <Typography variant="body1">{message.content}</Typography>
          </Box>
        </Box>
      ))}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <Box
            sx={{
              maxWidth: '70%',
              p: 2,
              bgcolor: 'grey.300',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 2,
            }}
          >
            <CircularProgress size={24} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MessageList;