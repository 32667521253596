import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

export const dataset = [
  {
    quantity: 5,
    week: 'semana 1',
  },
  {
    quantity: 8,
    week: 'semana 2',
  },
  {
    quantity: 15,
    week: 'semana3',
  },
  {
    quantity: 3,
    week: 'semana 4',
  },
];

function valueFormatter(value: number | null) {
  return `${value} h`;
}

const chartSetting = {
  yAxis: [
    {
      label: 'horas (h)',
    },
  ],
  width: 400,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-10px, 0)',
    },
  },
};

export const mock = [
  {
    user: 'Adrián Cueva Sáenz',
    puntuation: '315',
    avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg'
  },
  {
    user: 'Mary Ordinola',
    puntuation: '305',
    avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg'
  },
  {
    user: 'Usuario 3',
    puntuation: '200',
    avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg'
  }
];

const ChartSection = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return(
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={4}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Typography align="center" variant="h6" component="h1">Temas abordados</Typography>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: 'Pruebas Generadas', color: '#c1ff72' },
                  { id: 1, value: 15, label: 'Simulación de Entrevistas', color: '#b5eb72' },
                  { id: 2, value: 20, label: 'Casos de Estudio', color: '#aad772' },
                  { id: 3, value: 10, label: 'Resúmenes y Explicaciones', color: '#9fc372' },
                  { id: 4, value: 10, label: 'Recordatorios de Estudio', color: '#94af72' },
                  { id: 5, value: 10, label: 'Foro de Preguntas y Respuestas', color: '#899b72' },
                  { id: 6, value: 10, label: 'Actualizaciones de Normas', color: '#7e8772' },
                  { id: 7, value: 15, label: 'Listas de Verificación', color: '#737373' },
                ],
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -45,
                endAngle: 225,
                cx: 150,
                cy: 150,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
            width={300}
            height={300}
          />
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={4}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Typography align="center" variant="h6" component="h1">Horas por semana</Typography>
          <BarChart
            dataset={dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'week' }]}
            series={[
              { dataKey: 'quantity', valueFormatter, color: '#9fc372' }
            ]}
            {...chartSetting}
          />
        </Box>
      </Grid>
      <Grid item container alignItems={'center'} xs={12} md={4}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Typography align="center" variant="h6" component="h1">Top 3 usuarios</Typography>
          {mock.map((item, i) => (
            <Grid
              item
              xs={12}
              key={i}
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:last-child': {
                  borderBottom: 0,
                },
                marginTop: 3.6
              }}
            >
              <ListItem
                disableGutters
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <ListItemAvatar>
                  <Box
                    component={Avatar}
                    width={{ xs: 80, sm: 80, md: 50 }}
                    height={{ xs: 80, sm: 80, md: 50 }}
                    src={item.avatar}
                    marginRight={2}
                  />
                </ListItemAvatar>
                <ListItemText primary={item.user} secondary={item.puntuation} />
              </ListItem>
            </Grid>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChartSection;