import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const confirm = createAsyncThunk<any, any>(
  'user/confirm',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/auth/confirm`, userCredentials);
    const response = await request.data.data;
    localStorage.setItem('token', response.token);
    return response;
  }
);

export const resend = createAsyncThunk<any, any>(
  'user/resend',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/auth/resend`, userCredentials);
    const response = await request.data.data;
    localStorage.setItem('token', response.token);
    return response;
  }
);

export const changePassword = createAsyncThunk<any, any>(
  'user/changePassword',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/change_password`, userCredentials);
    const response = await request.data.data;
    localStorage.removeItem('token');
    return response;
  }
);

export const forgotPassword = createAsyncThunk<any, any>(
  'user/forgotPassword',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/forgot_password`, userCredentials);
    const response = await request.data.data;
    localStorage.removeItem('token');
    return response;
  }
);

export const recoverPassword = createAsyncThunk<any, any>(
  'user/recoverPassword',
  async (userCredentials) => {
    const request = await axios.post(`${baseUrl}/recover_password`, userCredentials);
    const response = await request.data.data;
    localStorage.removeItem('token');
    return response;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    user: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(confirm.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(confirm.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(confirm.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(resend.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(resend.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(resend.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(changePassword.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(forgotPassword.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    }).addCase(recoverPassword.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    }).addCase(recoverPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    }).addCase(recoverPassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      console.log(action.error.message);
      state.error = action.error.message;
    });
  }
});

export default userSlice.reducer;